import type { InjectionKey } from 'vue'

// models
import {
  WorldPortal,
  LIST_TYPE,
  getWorldLikesResponse,
  SortType,
  WorldSortData,
  SORT_TYPE,
} from '@/models/worldPortal'
// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { getWorldPortalLikesResponse } from '@/composables/repository/useWorldPortalLikeRepository'
import { isFetchError } from '@/composables/repository/useOhmyfetch'
import {
  GetWorldPortalsRequest,
  getWorldPortalsResponse,
  getWorldPortalDetailResponse,
  getRecommendWorldPortalListResponse,
} from '@/composables/repository/useWorldPortalRepository'
// modules
import { isValueOf } from '@/utils/zod'

export const useWorldPortal = () => {
  const repositoryFactory = useRepositoryFactory()
  const worldPortalRepository = repositoryFactory.get('worldPortal')
  const worldLikeRepository = repositoryFactory.get('worldLike')
  const worldPortalLikeRepository = repositoryFactory.get('worldPortalLike')
  const i18n = useI18n()

  type WorldPortalStateType = {
    currentWorldPortal: WorldPortal | null
    worldPortalList: WorldPortal[]
    worldPortalItemsByNew: WorldPortal[]
    worldPortalItemsByPickUp: WorldPortal[]
    worldPortalItemsByPopular: WorldPortal[]
    worldPortalItemsByOfficial: WorldPortal[]
  }

  const state = useState<WorldPortalStateType>('world_portal', () => ({
    currentWorldPortal: null,
    worldPortalList: [],
    worldPortalItemsByNew: [],
    worldPortalItemsByPickUp: [],
    worldPortalItemsByPopular: [],
    worldPortalItemsByOfficial: [],
  }))

  // pager情報
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)
  const page = ref(0)

  // ▼▼▼ ソート用 ▼▼▼
  const sortType = ref<SortType>('newer')
  const sortItems: WorldSortData[] = [
    {
      value: SORT_TYPE.NEWER,
      text: i18n.t('sort.play-world.newer'),
    },
    {
      value: SORT_TYPE.OLDER,
      text: i18n.t('sort.play-world.older'),
    },
    {
      value: SORT_TYPE.POPULAR,
      text: i18n.t('sort.play-world.popular'),
    },
  ]
  // ▲▲▲ ソート用 ▲▲▲

  /**
   * 「ワールド一覧取得」APIを実行し結果をstateに保存する
   * @param param APIに渡すパラメータ
   * @returns ワールドポータル一覧
   */
  const getWorldPortalList = async (
    param?: Omit<GetWorldPortalsRequest, 'offset'> & {
      page?: number
      type?: string
    }
  ) => {
    limit.value = param?.limit || limit.value
    page.value = param?.page || 1
    offset.value = page.value * limit.value - limit.value
    const response = await worldPortalRepository.get.getWorldPortals({
      limit: limit.value,
      offset: offset.value,
      ...param,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getWorldPortalsResponse, response)) {
      console.error('An API response is different.')
    }

    if (param?.type === LIST_TYPE.NEW)
      state.value.worldPortalItemsByNew = response.worldPortals
    if (param?.type === LIST_TYPE.POPULAR)
      state.value.worldPortalItemsByPopular = response.worldPortals
    if (param?.type === LIST_TYPE.OFFICIAL)
      state.value.worldPortalItemsByOfficial = response.worldPortals

    state.value.worldPortalList = response.worldPortals
    total.value = response.worldPortalCount

    return state.value.worldPortalList
  }

  /**
   * 「おすすめワールド一覧取得」APIを実行し結果をstateに保存する
   * @returns おすすめワールド一覧取得
   */
  const getRecommendWorldPortalList = async () => {
    const response =
      await worldPortalRepository.get.getRecommendWorldPortalList()

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getRecommendWorldPortalListResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.worldPortalItemsByPickUp = response.worldPortals
    total.value = response.worldPortals.length

    return state.value.worldPortalItemsByPickUp
  }

  /**
   * 「ワールド詳細取得」APIを実行し結果をstateに保存する
   * @param worldId ワールドポータルID
   * @returns ワールドポータル詳細
   */
  const getWorldPortalDetail = async (id: number) => {
    try {
      // ワールドポータル詳細初期化
      state.value.currentWorldPortal = null

      const response = await worldPortalRepository.get.getWorldPortalDetail(id)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getWorldPortalDetailResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.currentWorldPortal = response.worldPortal

      return state.value.currentWorldPortal
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  /**
   * 指定したvketIdの所持している「ワールド一覧取得」APIを実行し結果をstateに保存する
   * @param param APIに渡すパラメータ
   * @returns ワールドポータル一覧
   */
  const getWorldPortalListVketIdWithPager = async (
    vketId: string,
    page: number,
    _limit?: number
  ) => {
    const _offset = (page - 1) * (_limit || limit.value)
    const response = await worldPortalRepository.get.getWorldPortals({
      creatorVketId: vketId,
      filter: 'user',
      offset: _offset,
      limit: _limit || limit.value,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getWorldPortalsResponse, response)) {
      console.error('An API response is different.')
    }

    limit.value = _limit || limit.value
    offset.value = _offset
    state.value.worldPortalList = response.worldPortals
    total.value = response.worldPortalCount

    return state.value.worldPortalList
  }

  /**
   * ソートタイプを更新する
   */
  const updateSortType = (type: SortType) => {
    sortType.value = type
  }

  /**
   * 「いいね作成」APIを実行する
   * @param worldSetId world_set_id
   * @returns メッセージ
   */
  const postWorldLike = async (worldSetId: number) => {
    try {
      const response = await worldLikeRepository.post.postWorldLikes(worldSetId)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getWorldLikesResponse, response)) {
        console.error('An API response is different.')
      }

      // 「いいね」状態更新
      changeWorldLikeState(response.world)

      return response.world
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  /**
   * 「いいね解除」APIを実行する
   * @param worldSetId world_set_id
   * @returns メッセージ
   */
  const deleteWorldLike = async (worldSetId: number) => {
    try {
      const response = await worldLikeRepository.delete.deleteWorldLikes(
        worldSetId
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getWorldLikesResponse, response)) {
        console.error('An API response is different.')
      }

      // 「いいね」状態更新
      changeWorldLikeState(response.world)

      return response.world
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  /**
   * 「いいね」状態更新
   * @param response WorldPortal
   */
  const changeWorldLikeState = (response: WorldPortal) => {
    state.value.currentWorldPortal = response

    state.value.worldPortalList = state.value.worldPortalList.map(
      (worldPortal) => (worldPortal.id === response.id ? response : worldPortal)
    )

    state.value.worldPortalItemsByNew = state.value.worldPortalItemsByNew.map(
      (worldPortal) => (worldPortal.id === response.id ? response : worldPortal)
    )

    state.value.worldPortalItemsByPickUp =
      state.value.worldPortalItemsByPickUp.map((worldPortal) =>
        worldPortal.id === response.id ? response : worldPortal
      )

    state.value.worldPortalItemsByPopular =
      state.value.worldPortalItemsByPopular.map((worldPortal) =>
        worldPortal.id === response.id ? response : worldPortal
      )

    state.value.worldPortalItemsByOfficial =
      state.value.worldPortalItemsByOfficial.map((worldPortal) =>
        worldPortal.id === response.id ? response : worldPortal
      )
  }

  /**
   * 「いいねしたワールドポータル一覧取得」を実行し結果をstateに保存する
   * @param _limit リミット
   * @param _offset オフセット
   * @returns いいねしたワールドポータル一覧
   */
  const getWorldPortalLikes = async (_limit: number, _offset: number) => {
    try {
      const response = await worldPortalLikeRepository.get.getWorldPortalLikes(
        _limit,
        _offset
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getWorldPortalLikesResponse, response)) {
        console.error('An API response is different.')
      }

      limit.value = _limit
      offset.value = _offset
      total.value = response.likedWorldPortalCount
      state.value.worldPortalList = response.likedWorldPortals
      return state.value.worldPortalList
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  const getWorldPortalLikesWithPager = (page: number) => {
    const _offset = (page - 1) * limit.value
    return getWorldPortalLikes(limit.value, _offset)
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    page,
    sortType: readonly(sortType),
    sortItems,
    getWorldPortalList,
    getRecommendWorldPortalList,
    getWorldPortalDetail,
    updateSortType,
    postWorldLike,
    deleteWorldLike,
    getWorldPortalLikes,
    getWorldPortalLikesWithPager,
    getWorldPortalListVketIdWithPager,
  }
}

export type WorldPortalComposable = ReturnType<typeof useWorldPortal>

export const worldPortalComposablesInjectionKey: InjectionKey<WorldPortalComposable> =
  Symbol('world-portal-composable')
